<template>
  <div class="row">
    <div class="col-6">
      <card type="tasks" class="stacked-form">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <base-input>
                <label>ID:</label>
                <el-input placeholder="Id" v-model="user.id" disabled>
                </el-input>
              </base-input>
            </div>
            <div class="col-12">
              <base-input>
                <label>Nome de usúario:</label>
                <el-input placeholder="Nome de usuário" v-model="user.username" :disabled="disabledField">
                </el-input>
              </base-input>
            </div>
            <div class="col-12">
              <base-input>
                <label>E-mail:</label>
                <el-input placeholder="Email" v-model="user.email" :disabled="disabledField">
                </el-input>
              </base-input>
            </div>
            <div class="col-12">
              <base-input>
                <label>Primeiro nome:</label>
                <el-input placeholder="Primeiro nome" v-model="user.firstName">
                </el-input>
              </base-input>
            </div>
            <div class="col-12">
              <base-input>
                <label>Sobrenome:</label>
                <el-input placeholder="Sobrenome" v-model="user.lastName">
                </el-input>
              </base-input>
            </div>
            <div class="col-12" v-if="!disabledField">
              <base-input>
                <div>
                  <label>Senha:</label>
                  <el-tooltip content="Senha padrão 123@mudar, no primeiro acesso o usuário precisará modificar."
                    effect="light" :open-delay="100" placement="top">
                    <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
                  </el-tooltip>
                </div>
                <el-input placeholder="123@mudar" disabled>
                </el-input>
              </base-input>
            </div>
            <div class="col-3">
              <base-input>
                <label>Status:</label><br />
                <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                  v-model="user.enabled" :sync="true" />
              </base-input>
            </div>
            <div class="col-12" v-if="disabledField">
              <button class="custom-btn-add" @click="reset">Resetar senha</button>
            </div>
          </div>
        </div>
      </card>
    </div>
    <div class="col-6" v-if="disabledField">
      <card class="stacked-form">
        <h4 class="card-title">Grupos
        </h4>
        <div class="row">
          <div class="col-md-6">
            <h5 class="card-title">Disponíveis</h5>
            <select multiple style="height: 200px; width: 300px;" v-model="SelectedAvailableGroups">
              <option v-for="item in AvailableGroups" :key="item.id" :label="item.name" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <base-button type="primary" small class="btn-next" @click="addUserGroup(SelectedAvailableGroups)">
              Adicionar selecionados
            </base-button>
          </div>
          <div class="col-md-6">
            <h5 class="card-title">Associados</h5>
            <select multiple style="height: 200px; width: 300px;" v-model="SelectedAssociatedGroups">
              <option v-for="item in AssociatedGroups" :key="item.id" :label="item.name" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <base-button type="primary" small class="btn-next" @click="deleteUserGroup(SelectedAssociatedGroups)">
              Remover selecionados
            </base-button>
          </div>
        </div>
      </card>
      <card class="stacked-form" v-if="AvailableFranchises && AssociatedFranchises">
        <h4 class="card-title">Franquias
        </h4>
        <div class="row">
          <div class="col-md-6">
            <h5 class="card-title">Disponíveis</h5>
            <select multiple style="height: 200px; width: 300px;" v-model="SelectedAvailableFranchises">
              <option v-for="item in AvailableFranchises" :key="item.Id" :label="item.Name" :value="item">
                {{ item.name }}
              </option>
            </select>
            <base-button type="primary" small class="btn-next" @click="addUserFranchise()">
              Adicionar selecionados
            </base-button>
          </div>
          <div class="col-md-6">
            <h5 class="card-title">Associadas</h5>
            <select multiple style="height: 200px; width: 300px;" v-model="SelectedAssociatedFranchises">
              <option v-for="item in AssociatedFranchises" :key="item.Id" :label="item.Name" :value="item">
                {{ item.name }}
              </option>
            </select>
            <base-button type="primary" small class="btn-next" @click="deleteUserFranchise()">
              Remover selecionados
            </base-button>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { EventBus } from "@/eventBus";

export default {
  computed: {
    ...mapState('user', ['users', 'userCurrent', 'userPermissions', 'roles', 'userGroups']),
    ...mapState(['ecommerce']),
    ...mapState('group', ['groups', 'groupCount']),
    ...mapState('franchise', ['franchises']),
  },
  data() {
    return {
      user: {
        id: null,
        username: "",
        enabled: true,
        email: "",
        lastName: null,
        firstName: null,
        credentials: [
          {
            type: "password",
            value: "123@mudar",
            temporary: true
          }
        ],
        attributes: {
          franchise: []
        },
        groups: [
          {
            id: null,
            name: ""
          }
        ]
      },
      dataUserPermissionsAdd: [
        {
          id: null,
          name: null
        }
      ],
      dataUserPermissionsRemove: [
        {
          id: null,
          name: null
        }
      ],
      disabledField: false,
      param: {
        id: null,
        idGroup: null,
        name: null,
        realm: null,
        bodyAdd: [{
          id: null,
          name: null
        }],
        bodyRemove: [{
          id: null,
          name: null
        }],
      },
      AvailableGroups: [],
      AssociatedGroups: [],
      SelectedAvailableGroups: [],
      SelectedAssociatedGroups: [],
      SelectedAvailableFranchises: [],
      AvailableFranchises: [],
      AssociatedFranchises: [],
      SelectedAssociatedFranchises: []
    }
  },
  methods: {
    ...mapActions('group', ['getGroups']),
    ...mapActions('franchise', ['getFranchisesByEcommerce']),
    ...mapActions('user', ['getUser', 'resetPassword']),

    save() {
      this.$emit("save");
    },
    validate() {
      if (this.$isNullOrEmpty(this.user.username)) {
        this.$showError("Nome de usúario obrigatório.");
        return false;
      } else if (this.$isNullOrEmpty(this.user.email)) {
        this.$showError("E-mail obrigatório.");
        return false;
      } else if (this.$isNullOrEmpty(this.user.firstName)) {
        this.$showError("Primeiro nome obrigatório.");
        return false;
      } else
        return true;
    },
    getValue() {
      const groups = [];

      this.userGroups.forEach(initial => {
        const item = this.AvailableGroups.find(removeGroup => removeGroup.name === initial.name);

        if (item) {
          groups.push({
            id: initial.id,
            name: initial.name,
            active: false
          });
        }
      });

      this.AssociatedGroups.forEach(addGroup => {
        const item = this.userGroups.find(initial => initial.name === addGroup.name);

        if (!item) {
          groups.push({
            id: addGroup.id,
            name: addGroup.name,
            active: true
          });
        }
      });

      if (this.user && !this.user.hasOwnProperty('attributes')) {
        this.user.attributes = {};
      }
      if (this.user && !this.user.attributes.hasOwnProperty('franchises')) {
        this.user.attributes.franchises = [];
      }


      this.user.attributes.franchises = this.AssociatedFranchises.map(item => String(item.Id));

      this.user.groups = groups;
      this.user.name = this.user.username.replace(/\s/g, '');
      this.user.email = this.user.email.replace(/\s/g, '');
      this.user.lastName = this.user.lastName.trim();
      this.user.firstName = this.user.firstName.trim();

      return this.user;
    },
    getValuePermission() {
      return this.dataUserPermissionsAdd;
    },
    async loadUser() {
      this.user = this.userCurrent;
      this.AvailableFranchises = this.franchises;
      let result = await this.getUser(this.user.username);

      if (result.data[0].hasOwnProperty('attributes') && result.data[0].attributes.hasOwnProperty('franchises')) {
        this.user.attributes = {};
        this.user.attributes.franchises = result.data[0].attributes.franchises;

        this.AssociatedFranchises = this.AvailableFranchises.filter(franchise =>
          this.user.attributes.franchises.map(String).includes(String(franchise.Id))
        );

        this.AvailableFranchises = this.AvailableFranchises.filter(franchise =>
          !this.user.attributes.franchises.map(String).includes(String(franchise.Id))
        );
      }

      this.dataUserPermissions = this.userPermissions;
      if (this.userCurrent) {
        this.disabledField = true;
      }
    },
    async loadGroups() {
      this.param.id = this.user.id;
      this.param.realm = this.$store.state.realm;
      let payload = Object.assign(this.param);
      await this.getGroups(payload);
    },
    getAvailableGroups() {
      this.AvailableGroups = this.groups.filter(group => !this.AssociatedGroups.find(associatedGroup => associatedGroup.name === group.name));
    },
    async getAssociatedGroups() {
      this.AssociatedGroups = this.userGroups.slice();
    },
    addUserFranchise() {
      this.SelectedAvailableFranchises.forEach(item => {
        this.AssociatedFranchises.push(item);
        this.AvailableFranchises = this.AvailableFranchises.filter(franchise => franchise.Id !== item.Id);
      });

      this.SelectedAvailableFranchises = [];
    },
    deleteUserFranchise() {
      this.SelectedAssociatedFranchises.forEach(item => {
        this.AvailableFranchises.push(item);
        this.AssociatedFranchises = this.AssociatedFranchises.filter(franchise => franchise.Id !== item.Id);
      });

      this.SelectedAssociatedFranchises = [];
    },
    async addUserGroup(SelectedAvailableGroups) {
      this.param.id = this.user.id;
      this.param.realm = this.$store.state.realm;

      SelectedAvailableGroups.forEach(async select => {
        this.param.idGroup = select;
        this.AssociatedGroups.push(...this.groups.filter(group => group.id === select));
        let index = this.AvailableGroups.findIndex(group => group.id === select);
        this.AvailableGroups.splice(index, 1);
      });
    },
    async deleteUserGroup(SelectedAssociatedGroups) {
      this.param.id = this.user.id;
      this.param.realm = this.$store.state.realm;

      SelectedAssociatedGroups.forEach(async select => {
        this.param.idGroup = select;
        this.AvailableGroups.push(...this.groups.filter(group => group.id === select));
        let index = this.AssociatedGroups.findIndex(group => group.id === select);
        this.AssociatedGroups.splice(index, 1);
      });
    },
    reset() {
      const data = {
        param: {
          id: this.user.id
        },
        formData: this.getValue()
      }

      this.resetPassword(data)
        .then(() => {
          this.$showSuccess("Senha resetada com sucesso");
          this.$router.push({ name: 'User' });
        })
        .catch(error => { this.$showError(error) });
    }
  },
  async mounted() {
    await this.loadGroups();
    EventBus.$on("loadUser", async () => await this.loadUser());
    await this.loadUser();
    await this.getAssociatedGroups();
    this.getAvailableGroups();
  }
}
</script>
<style>
.el-switch.is-checked .el-switch__core {
  border-color: #00ADB7 !important;
  background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
  color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
  color: #00ADB7;
  font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed !important;
}
</style>
