<template>
  <div>
    <card class="pl-4">
           <div class="d-flex justify-content-between">
             <div class="pb-2 pt-2 ">Período: 09/02/2025 até 11/03/2025</div>
             <div class="p-2">Última atualização: 11/03/2025</div>
           </div>
           <el-row :gutter="20">
             <el-col :span="4" style="border-left-style: double; border-color: #1aac96; border-width: 2px;">
               <div>
                 <p><strong>Produtos Analisados</strong></p>
                 <p>2547</p>
               </div>
             </el-col>
             <el-col :span="4" style="border-left-style: double; border-color: #1aac96; border-width: 2px;">
               <div>
                 <p><strong>NCMs analisados</strong></p>
                 <p>3</p>
               </div>
             </el-col>
             <el-col :span="4" style="border-left-style: double; border-color: #1aac96; border-width: 2px;">
               <div>
                 <p><strong>Ticket médio</strong></p>
                 <p>R$ 229,07</p>
               </div>
             </el-col>
             <el-col :span="4" style="border-left-style: double; border-color: #1aac96; border-width: 2px;">
               <div>
                 <p><strong>Valor imposto atual</strong></p>
                 <p>R$ 58.945,22</p>
               </div>
             </el-col>
             <el-col :span="4" style="border-left-style: double; border-color: #1aac96; border-width: 2px;">
               <div>
                 <p><strong>Valor imposto sugerido</strong></p>
                 <p>R$ 45.735,87</p>
               </div>
             </el-col>
             <el-col :span="4" style="border-left-style: double; border-color: #1aac96; border-width: 2px;">
               <div>
                 <p class="text-success"><strong>Economia prevista*</strong></p>
                 <p class="text-success">R$ 13.209,05</p>
               </div>
             </el-col>
           </el-row>
         </card>
     <card>
       <section>
         <base-list ref="baseList" :totalRecords="totalRecords" @changePage="search">
             <h4 style="display: flex; align-items: center; margin-bottom: 0px" slot="header" class="card-title">
                 Análise de Aliquotas
             </h4>
             <div>
               <div class="pl-2 mt-2 row">
                 <span class="mr-2">
                   <base-input>
                     <el-date-picker type="date" format="dd/MM/yyyy" placeholder="De" ></el-date-picker>
                   </base-input>
                 </span>
                 <span class="mr-2">
                   <base-input>
                     <el-date-picker type="date" format="dd/MM/yyyy" placeholder="Até" ></el-date-picker>
                   </base-input>
                 </span>
                 <span class="mr-2">
                   <base-input>
                     <el-input type="search" prefix-icon="el-icon-search" placeholder="NCM" 
                       />
                   </base-input>
                 </span>
                 <span class="mr-2">
                   <base-input>
                     <el-select class="select-primary"  placeholder="Filial" multiple>
                     </el-select>
                   </base-input>
                 </span>
                 <span class="mr-2">
                   <base-input>
                     <el-select class="select-primary"  placeholder="Origem de compra" multiple>
                     </el-select>
                   </base-input>
                 </span>
                 <span class="mr-2">
                   <button class="custom-btn-filter">Filtrar</button>
                 </span>
                 <span class="mr-2">
                   <button class="custom-btn-filter">
                     Limpar filtro
                   </button>
                 </span>
                 <span class="mr-2">
                   <base-input>
                     <el-dropdown >
                       <el-button class="custom-btn-save">
                         Exportar<i class="el-icon-arrow-down el-icon--right"></i>
                       </el-button>
                       <el-dropdown-menu slot="dropdown">
                         <el-dropdown-item >Todos</el-dropdown-item>
                       </el-dropdown-menu>
                     </el-dropdown>
                   </base-input>
                 </span>
               </div>
             </div>
             <el-table style="width: 100%" :data="ordersToTable" ref="orderTable" 
                  header-row-class-name="order-table-header-row">
                  <el-table-column type="selection" width="40" />
                  <el-table-column align="center" label="Data da Consulta" prop="data"/>
                  <el-table-column align="center" label="NCM" prop="ncm"/>
                  <el-table-column align="center" label="NCM Descrição" prop="ncmDescricao"/>
                  <el-table-column align="center" label="Filial" prop="filial"/>
                  <el-table-column align="center" label="Origem de Compra" width="300" prop="origemProduto"/>
                  <el-table-column align="center" label="Aliquota SAP" prop="aliquota"/>
                  <el-table-column align="center" label="Aliquota Sugerida" prop="aliquotaSugerida"/>
                  <el-table-column align="center" label="Ticket Médio" prop="ticketMedio"/>
                  <el-table-column align="center" label="Economia Prevista*" prop="economiaPrevista"/>
                  <el-table-column label="Status" align="center"  >
                    <template slot-scope="props">
                      <div v-if="props.row.status == 'Atenção'" class="status statusError">{{ props.row.status }}</div>
                      <div v-else class="status statusIntegrated">{{ props.row.status }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="105" align="center" label="Ações">
                    <div slot-scope="props" style="display: inline-flex;">
                        <base-button class="like btn-link" type="primary" size="sm" icon
                       >
                          <i class="material-icons">info</i>
                        </base-button>
                    </div>
                  </el-table-column>
                </el-table>
         </base-list>
     </section>
     </card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      totalRecords: 0,
      ordersToTable: [
        {
          data: '11/03/2025 07:51',
          ncm: '8471.60.52',
          ncmDescricao: 'Teclados',
          filial: '01 - SC',
          origemProduto: '2 - Estrangeira - Adquirida no mercado interno, excetp a indicada no código 7',
          aliquota: '18%',
          aliquotaSugerida: '16%',
          ticketMedio: 'R$ 229,07',
          economiaPrevista: 'R$ 13.209,05',
          status: 'Atenção'
        },
        {
          data: '11/03/2025 07:51',
          ncm: '8504.40.10',
          ncmDescricao: 'Carregadores',
          filial: '01 - SC',
          origemProduto: '2 - Estrangeira - Adquirida no mercado interno, excetp a indicada no código 7',
          aliquota: '18%',
          aliquotaSugerida: '12%',
          ticketMedio: 'R$ 120,94',
          economiaPrevista: 'R$ 5.782,80',
          status: 'Atenção'
        },
        {
          data: '11/03/2025 07:51',
          ncm: '9030.20.10',
          ncmDescricao: 'Osciloscópios digitais',
          filial: '02 - MG',
          origemProduto: '0 - Nacional, exceto as indicadas nos códigos 3 a 5',
          aliquota: '14%',
          aliquotaSugerida: '14%',
          ticketMedio: 'R$ 805,47',
          economiaPrevista: 'R$ 0,00',
          status: 'OK'
        }
      ]
    }
  },
  methods: {
    search() {
      this.totalRecords = 10
    }
  }
}
</script>
<style scoped>
.cell {
  font-size: 9pt !important;
}
</style>