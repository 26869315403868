<template>
  <div>
    <h4 class="ml-3 mt-2" id="v-step-23">Agendamentos</h4>
    <div class="d-flex flex-wrap justify-content-start">
      <div class="card m-2 card-custom" v-for="item in configurationArray" :key="item.Id">
        <div class="card-body">
          <div class="card-title d-flex justify-content-between" style="margin-bottom: 1px">
            <h5 style="margin-bottom: 1px">{{ item.Name }}</h5>
            <el-tooltip
              content="Configurações do agendamento"
              effect="light"
              :open-delay="100"
              placement="top"
            >
              <i @click="handleEdit(item)" class="tim-icons icon-settings-gear-63"></i>
            </el-tooltip>
          </div>
          <small class="card-text mb-2">{{ getCron(item.Cron) }}</small
          ><br />
          <toggle-button
            class="mt-3"
            :labels="{ checked: 'ON', unchecked: 'OFF' }"
            color="#7dd7dc"
            input
            type="checkbox"
            v-model="item.Active"
            :sync="true"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <UpdateLog :dataLog="dataLog" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div
          class="d-flex justify-content-center justify-content-between flex-wrap flex-row-reverse"
        >
          <button
            class="custom-btn-save"
            @click="saveSchedules()"
            v-if="getUserPermission('36_c')"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="missingStepsModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="missingStepsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" @click="hideModal()">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-2">
              <span
                >Você ainda não completou todos os passos para ativar essa função. Para
                agendar a
                <span class="font-weight-bold">{{ recurringJobName }}</span>
                você precisa:
              </span>
            </div>
            <div v-for="element in missingRecurringJobs" :key="element.Key">
              <a v-bind:href="element.Value">
                {{ element.Key }}
              </a>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-end">
            <button type="button" class="btn btn-primary" @click="hideModal()">
              Entendi!
            </button>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import planMixin from "src/mixins/planMixin.js";
import UpdateLog from "../../../components/UpdateLog.vue";
import { mapMutations } from "vuex";
import constants from "@/util/constants";
import moment from "moment";

export default {
  mixins: [planMixin],
  components: {
    UpdateLog,
  },
  data() {
    return {
      dataLog: [],
      configurationArray: [],
      checkFunctionality: false,
      isSincronizeProduct: false,
      recurringJobName: "",
      missingRecurringJobs: [],
      isStandard: false,
      tableData: [
        {
          name: "Preço",
        },
        {
          name: "Estoque",
        },
      ],
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    ...mapMutations("configurations", ["SET_SCHEDULE_CURRENT"]),
    dateFormatter(row, col, value, index) {
      return moment.utc(value).format("DD/MM/YYYY HH:mm:ss");
    },
    async init() {
      let responseSchedules = await this.$bahngleis.get(
        `RecurringJobs?ecommerceName=${this.$store.state.ecommerce.Name}`
      );
      this.configurationArray = await responseSchedules.data;
      this.GetLocaleName();

      let responseLog = await this.$bahngleis.get(
        "/LogAction/getlogsofentity?identity=" +
          this.$store.state.ecommerce.Name +
          "&entity=RecurringJobs"
      );

      this.dataLog = await responseLog.data;
    },
    handleEdit(item) {
      this.SET_SCHEDULE_CURRENT(item);
      this.$router.push({ name: "ScheduleDetail" });
    },
    getCron(cron) {
      let cronOption = constants.CronOptions.find((a) => a.Key === cron);
      return cronOption ? cronOption.Label : " --- ";
    },
    productValidation(item) {
      if (this.isStandard && item.Name == "Sincronização de Produtos") return false;

      return true;
    },
    GetLocaleName() {
      let SchedulesNames = [
        {
          name: "RequeueOrder",
          value: "Reenfileirar Pedidos",
          info: "",
        },
        {
          name: "RequeueFulfillment",
          value: "Reenfileirar Pedidos de Fulfillment",
          info: "",
        },
        {
          name: "ScheduleCastOrder",
          value: "Sincronização de Pedidos",
          info:
            "Responsável pela captação de pedidos da origem (e-commerce ou Platz) e envio deles para o SAP.",
        },
        {
          name: "ScheduleIncomingPaymentsSynchronizer",
          value: "Sincronização de Contas a Receber",
          info:
            "Responsável por gerar o documento Contas a Receber no SAP. Você poderá habilitar apenas caso tenha adquirido a funcionalidade em seu plano.",
        },
        {
          name: "ScheduleStatusSynchronizer",
          value: "Sincronização de Status",
          info:
            "Responsável por realizar a atualização de status no e-commerce, tendo como o origem o SAP: Status: Integrado | Cancelado | Faturado.",
        },
        {
          name: "SchedulePriceSynchronizer",
          value: "Sincronização de Preço no Ecommerce",
          info:
            "Responsável por realizar a atualização de preços, tendo como origem o SAP e o destino a plataforma de e-commerce.",
        },
        {
          name: "ScheduleStockSynchronizer",
          value: "Sincronização de Estoque no Ecommerce",
          info:
            "Responsável por realizar a atualização de saldo de estoque, tendo como origem o SAP e o destino a plataforma de e-commerce.",
        },
        {
          name: "SyncProducts",
          value: "Sincronização de Produtos",
          info:
            "Esta funcionalidade ainda não foi lançada, porém será responsável pelo envio de cadastro de produtos para o e-commerce.",
        },
        {
          name: "SyncImageProducts",
          value: "Sincronização de Imagens dos Produtos",
          info: "",
        },
        {
          name: "SchedulePurchaseDeliveryTransfer",
          value: "Sincronização de Transferência de Entrega",
          info: "",
        },
        {
          name: "ScheduleSAPPriceSynchronizer",
          value: "Sincronização de Preço com o SAP",
          info:
            "Responsável por realizar a atualização de preços, tendo como origem o SAP e o destino a plataforma de e-commerce.",
        },
        {
          name: "ScheduleSAPStockSynchronizer",
          value: "Sincronização de Estoque com o SAP",
          info:
            "Responsável por realizar a atualização de saldo de estoque, tendo como origem o SAP e o destino a plataforma de e-commerce.",
        },
        {
          name: "ScheduleCissStockSynchronizer",
          value: "Sincronização de Estoque com o CISS",
          info:
            "Responsável por realizar a atualização de saldo de estoque, tendo como origem o CISS e o destino a plataforma de e-commerce.",
        },
        {
          name: "ScheduleSyncDocNum",
          value: "Sincronização de Status Fulfillment",
          info:
            "Responsável por realizar a atualização de status dos pedidos Fulfillment no Platz.",
        },
        {
          name: "SyncLongTailProducts",
          value: "Sincronização de skus de cauda longa",
          info:
            "Responsável por realizar a sincronização de SKUs de cauda longa no Magento 2.",
        },
      ];
      this.configurationArray.map((element, i) => {
        SchedulesNames.map((el) => {
          if (element.Name == el.name) {
            element.Name = el.value;
            element.Info = el.info;
          }
        });
      });
    },
    async saveSchedules() {
      try {
        for (var i = 0; i < this.configurationArray.length; i++) {
          let element = this.configurationArray[i];
          await this.getValidationError(element);
          await this.$bahngleis.patch("RecurringJobs/update", element);
        }

        this.$notify({
          message: "Sucesso! Sua rotina está em agendamento",
          timeout: 3000,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "success",
        });

        this.init();
      } catch (error) {
        this.$notify({
          message: "Ops! Houve um erro ao salvar as configurações dos agendamentos",
          timeout: 3000,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },
    getValidationError: async function (recurringJob) {
      if (recurringJob.Active == true) {
        let response = await this.$bahngleis.get(
          `RecurringJobs/GetValidation?id=${recurringJob.Id}&ecommerceName=${this.$store.state.ecommerce.Name}`
        );

        if (response.data != "") {
          await this.redirectMissingSteps(response.data);
          this.isMissingSteps(recurringJob);
        }
      }
    },
    redirectMissingSteps: async function (missingStepsError) {
      this.missingRecurringJobs = [];

      await missingStepsError.forEach((element) => {
        this.missingRecurringJobs.push(element);
      });
    },
    isMissingSteps(recurringJob) {
      recurringJob.Active = !recurringJob.Active;
      this.recurringJobName = recurringJob.Name;
      this.showModal();
    },
    showModal() {
      $("#missingStepsModal").modal("show");
      $("div").removeClass("modal-backdrop");
    },
    hideModal() {
      $("#missingStepsModal").modal("hide");
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    },
  },
};
</script>
<style>
#missingStepsModal .modal-body {
  color: black;
}

#missingStepsModal .modal-body span {
  color: black;
}

#missingStepsModal .modal-title {
  font-weight: 600;
  color: #525f7d;
}

#missingStepsModal .close {
  z-index: 10;
}

.width-tooltip {
  width: 50%;
}

.align-middle {
  vertical-align: middle;
}

@media screen and (min-width: 1731px) {
  .card-custom {
    width: 32.2rem;
  }
}

@media screen and (max-width: 1366px) {
  .card-custom {
    width: 21rem;
  }
}
</style>
